import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {addFruits, setToolState, setOpportunityCardState, activeOpportunity, makeContribution, setNotesTool, selectContribution} from '../redux_actions';
let PartTwo = class PartTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: null
    };
  }
  render() {
    let appState = this.props.app.status;
    let activeLog = this.props.app.activeLog||{};
    let logId = activeLog.logId||null;
    let log = activeLog.log||{};
    let opportunities = this.props.app.settings["-opportunities"];
    let probableResponses = this.props.app.settings["-probableResponses"];
    let uid = this.props.auth.user.user.uid;
    let { setOpportunityCardState } = this.props;
    let oKey = appState.activeOpportunity;
    let opportunity = oKey?opportunities[oKey] : {};
    let activeCluster = appState.activeCluster;

    return <Slide
      className="opportunity-cntr tool-pg"
      direction="left"
      mountOnEnter
      unmountOnExit
      in={appState.opportunityCardVisible}>
      <div className="tool-cntr">
        <IconButton
          color="inherit"
          onClick={() => {
          setOpportunityCardState(false)
        }}
          aria-label="Close">
          <CloseIcon/>
        </IconButton>

        <div className="opportunity-header">
          <div>Contribution:</div>
          <div>{opportunity.text || ""}</div>
        </div>

        <div className="opportunity-body">
          <div className="section-sub-title">{opportunity.trigger}</div>
          <RadioGroup
            className="contribution-options"
            aria-label={oKey}
            name={oKey}
            value={this.state.selectedValue}
            onChange={(ev) => {
            this.setState({selectedValue: ev.target.value})
          }}>
            {listProbableResponses(opportunity.probableResponses, probableResponses)}
          </RadioGroup>
        </div>
        <div
          className="active btn confirm-contribution"
          onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          let cid = this.state.selectedValue;
          let fruits = probableResponses[cid].fruits||{};
          if(cid){
            this.props.selectContribution(cid);
            this.props.addFruits(fruits);
            this.props.makeContribution({logId, log, uid, oid:oKey, cid, activeCluster});
            this.props.setNotesTool(true);
          }
        }}>Select</div>
      </div>
    </Slide>
  }
};

function listProbableResponses(refs, probableResponses) {
  refs = refs || {};
  let pathData = `M210.099,102.361c-31.381,10.072-34.338,20.326-8.831,44.104 
	c-35.825-6.161-43.187,1.2-33.314,30.095c-27.488-18.576-37.741-15.619-44.925,12.655c-14.707-28.274-24.96-31.231-48.192-5.257
	c5.616-36.293-1.746-43.654-43.591-27.123c31.527-34.146,28.569-44.401-23.346-52.887c51.915-13.404,54.873-23.658,15.54-60.411
	c49.652,19.137,57.013,11.775,50.87-28.633c23.759,30.089,34.013,27.132,50.989-1.902c4.914,29.034,15.167,31.992,42.881,15.874
	c-10.098,26.437-2.736,33.798,29.463,30.914C175.761,80.289,178.718,90.543,210.099,102.361z`
  
  
let icon = <svg xmlns="http://www.w3.org/svg/2000"
  viewBox={"0 0 220.408 198.98"}
  width={"24"}
  height={"24"}
  stroke={"#FBBB38"}
  strokeWidth="10"
  fill={"#FFFFFF"}>
  <path d={pathData} />
  </svg>;

let checkedIcon = <svg xmlns="http://www.w3.org/svg/2000"
  viewBox={"0 0 220.408 198.98"}
  width={"24"}
  height={"24"}
  stroke={"#FBBB38"}
  strokeWidth="12"
  fill={"#FBBB38"}>
  <path d={pathData} />
</svg>;


  return Object
    .keys(refs)
    .map(key => {
      return <FormControlLabel
        key={key}
        className="contribution-type"
        value={key}
        control={< Radio icon={icon} checkedIcon={checkedIcon}/> }
        label={probableResponses[key].text}/>
    });
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    setToolState, addFruits, setOpportunityCardState, activeOpportunity, makeContribution, setNotesTool, selectContribution
  }, dispatch);
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PartTwo));