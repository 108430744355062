import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from "react-router-dom";
import {
  setToolState,
  setOpportunityCardState,
  activeOpportunity,
  makeContribution,
  resetTool,
  activeCluster,
  setImpactDialog
} from '../redux_actions';
import Impact from "./impact";

let PartOne = class PartOne extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // let that = this;
    let appState = this.props.app.status;
    let impactDialogActive = appState.impactDialogActive;
    let activeLog = this.props.app.activeLog;
    let logId = activeLog.logId;
    let log = activeLog.log||{};

    let opportunities = this.props.app.settings["-opportunities"];
    let probableResponses = this.props.app.settings["-probableResponses"];
    let activeContribution = appState.activeContribution;
    // let oKey = appState.activeOpportunity;
    let clusters = this.props.app.settings["-cluster"];
    let setOpportunityCardState = this.props.setOpportunityCardState;
    let activeOpportunity = this.props.activeOpportunity;
    let activeCluster = this.props.activeCluster;
    let impactActionButton = this.props.app.settings.texts["impact-action-continue"];
    let impactText = probableResponses[activeContribution] && probableResponses[activeContribution].impact
      ? probableResponses[activeContribution].impact
      : "No Impact is defined for this selection"
    return <div style={{
      flexGrow: 1,
      paddingBottom: "32px"
    }}>
      <div className="section-title panel-header">
        <div className="section-title-text">{this.props.app.settings.texts["trigger-part-one"] || ""}</div>
        <IconButton
          onClick={(ev) => {
          this
            .props
            .setToolState(false);
          this.props.resetTool();
        }}
          className="close-btn-top"
          aria-label="Close">
          <Link to="/application"><CloseIcon/></Link>
        </IconButton>
      </div>
      {populateClusters()}
      {submitButton()}
      {Impact(impactDialogActive, impactText, this.props.setImpactDialog, impactActionButton)}
    </div>

    function populateClusters() {
      return Object
        .keys(clusters)
        .map(clusterKey => {
          let cluster = clusters[clusterKey];
          let _opportunities = {};
          Object.keys(cluster["-opportunities"] || {}).forEach(el => {
            _opportunities[el] = opportunities[el];
          })
          return populateCluster({clusterKey, cluster, _opportunities});
        });
    };

    function populateCluster({clusterKey, cluster, _opportunities}) {

      return <ExpansionPanel
        expanded={clusterKey === appState.activeCluster}
        key={clusterKey}
        className="expansion-panel"
        onChange={(ev, expanded) => {
        activeCluster(expanded
          ? clusterKey
          : null)
      }}>
        <ExpansionPanelSummary className="expansion-panel-summary" expandIcon={< ExpandMoreIcon />}>
          <div className="cluster-title-cntr">
            <div className="cluster-title">{cluster.title}</div>
            {counter(clusterKey)?<div className="cluster-count">{counter(clusterKey)}</div>:null}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="expansion-panel-details expansion-panel-details-cntr">
          <div>{populateOpportunities(_opportunities, clusterKey)}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    };

    function populateOpportunities(opportunities, clusterKey) {
      return Object
        .keys(opportunities)
        .map(opportunityKey => {
          return <div key={opportunityKey} className="opportunity">
            <div
              className="opportunity-text-cntr"
              onClick={(ev) => {
              activeOpportunity(opportunityKey);
              setOpportunityCardState(true)
            }}>{opportunities[opportunityKey].text}</div>
            {logId
              ? populateSelectedContributions(opportunityKey)
              : null}
          </div>
        });
    };

    function populateSelectedContributions(opportunityKey, clusterKey) {
      let selectedOnes = {};
      if (log) {
        let res = <div className="my-contributions">
          {Object
            .keys(log.contributions)
            .map(eventKey => {
              let event = log.contributions[eventKey];
              if (event.opportunity === opportunityKey) {
                selectedOnes[event.response] = true;
              }
            })
          }</div>

        return Object
          .keys(selectedOnes)
          .map(resKey => {
            return <div className="sel-cntr" key={"sel-" + resKey}>
              <span className="sel-text">{probableResponses[resKey].text}</span>
            </div>
          });
      }
      return null;
    }

    function submitButton() {
      let contributionCount = log.contributions
        ? Object
          .keys(log.contributions)
          .length
        : 0;
      return <div className="btn bottom part-one-submit">
        <div className="contribution-count">{contributionCount} {contributionCount < 2
            ? "contribution"
            : "contributions"}</div>
        {/* <Link onClick={() => _resetTool()} className="" to="/application/report">Done</Link> */}
        {contributionCount?<Link className="" to="/application/report">Done</Link>:null}
      </div>
    }

    function counter(clusterId) {
      let count = 0;
      if(log&&log.contributions){
        Object
        .keys(log.contributions)
        .forEach(eventKey => {
          let event = log.contributions[eventKey];
          if (event.cluster === clusterId) {
            count++;
          }
        });
      return count;
      }
    }

  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    setToolState,
    setOpportunityCardState,
    activeOpportunity,
    makeContribution,
    resetTool,
    activeCluster,
    setImpactDialog
  }, dispatch);
}

const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PartOne));