import firebase from "firebase/app";
import "firebase/database";
import firebase_config from "../../settings/firebase_config"

if (!firebase.app.length) {
  firebase.initializeApp(firebase_config);
}

export const setFooterVisible = visibility => {
  return dispatch => {
    dispatch({type: "SET_FOOTER_VISIBILITY", payload: visibility});
  };
};

export const setToolState = visibility => {
  return dispatch => {
    dispatch({type: "SET_TOOL_VISIBILITY", payload: visibility});
  };
};

export const setOpportunityCardState = visibility => {
  return dispatch => {
    dispatch({type: "SET_OPPORTUNITY_CARD_VISIBILITY", payload: visibility});
  };
};

export const setNotesTool = visibility => {
  return dispatch => {
    dispatch({type: "SET_NOTE_TOOL", payload: visibility});
  };
};

export const activeOpportunity = oid => {
  return dispatch => {
    dispatch({type: "SELECT_OPPORTUNITY", payload: oid});
  };
};

export const selectContribution = cid => {
  return dispatch => {
    dispatch({type: "SELECT_CONTRIBUTION", payload: cid});
  };
};

export const resetTool = () => {
  return dispatch => {
    dispatch({type: "RESET_TOOL"});
  };
};

export const activeCluster = (id) => {
  return dispatch => {
    dispatch({type: "SET_CLUSTER", payload: id});
  }
}

export const takeNotes = ({eventId, note, cid}) => {
  return dispatch => {
    dispatch({type: "SET_NOTE", payload: {eventId, note, cid}});
  }
}

export const setImpactDialog = (visibility) => {
  return dispatch => {
    dispatch({type: "SET_IMPACT_DIALOG", payload: visibility});
  }
}

export const addFruits = (fruits) =>{
  return dispatch => {
    dispatch({type: "ADD_FRUITS", payload: fruits})
  }
}

export const addCustomFruits = (fruits) =>{
  return dispatch => {
    dispatch({type: "ADD_CUSTOM_FRUITS", payload: fruits})
  }
}

export const rejectFruit = ({fid, status}) =>{
  return dispatch => {
    dispatch({type: "REJECT_FRUIT", payload: {fid, status}})
  }
}

export const updateContribution = ({
  logId,
  customFruits,
  uid
})=>{
  console.log(customFruits);
  let update = {};
  update[`users/${uid}/myContributionLogs/${logId}/customFruits`] = customFruits;
  firebase
    .database()
    .ref()
    .update(update)
};

export const makeContribution = ({
  logId,
  log,
  uid,
  oid,
  cid,
  eventId,
  activeCluster
}) => {
  if (!logId) {
    logId = firebase
      .database()
      .ref()
      .child(`users/${uid}/myContributionLogs/`)
      .push()
      .key;
  }

  if (!log) {
    log = {};
    log["timestamp"] = Date.now();
  }
  if (!log.contributions) {
    log.contributions = {};
  }
  if (!eventId) {
    eventId = firebase
      .database()
      .ref()
      .child(`users/${uid}/myContributionLogs/${logId}/`)
      .push()
      .key;
    log.contributions[eventId] = {};
    // log.contributions[eventId].timestamp = Date.now();
  };
  
  log.contributions[eventId].cluster = activeCluster;
  log.contributions[eventId].opportunity = oid;
  log.contributions[eventId].response = cid;
  log.contributions[eventId].createdOn = Date.now();  

  let update = {};
  update[`users/${uid}/myContributionLogs/${logId}/`] = log;
  firebase
    .database()
    .ref()
    .update(update);

  return dispatch => {
    dispatch({type: "SET_EVENT_ID", payload: eventId})
    dispatch({
      type: "SET_ACTIVE_LOG",
      payload: {
        logId,
        log
      }
    });
    dispatch({type: "SET_OPPORTUNITY_CARD_VISIBILITY", payload: false});
  }
}