import React, {Component} from "react";
import {Switch, Route} from "react-router-dom";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import AppDashboard from "./AppDashboard";
import UserJourney from "./UserJourney";
// import FooterNavigation from "../../components/FooterNavigation";
import {
  CSSTransition,
} from 'react-transition-group';

import "./application.css";
import Tool from "./Tool";
import Report from "./Report";

let Application = class Application extends Component {
  render() {
    // let {toolIsActive} = this.props.app.status;
    let appRoutes = <div className="app-screen">
    <Route render={({location}) => (
          <CSSTransition
            key={location.key}
            timeout={450}
            classNames="fade"
          >
            <Switch location={location}>
              <Route exact path="/application/" component={AppDashboard} />
              <Route path="/application/journey" component={UserJourney} />
              <Route path="/application/tool" component={Tool} />
              <Route path="/application/report" component={Report} />
            </Switch>
        </CSSTransition>
      )} />
    {/* <FooterNavigation footerVisible={this.props.app.status.footerVisible}/> */}
  </div>;
    return appRoutes; 
  }
}

const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, null)(Application));