import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";

import PartOne from "./part.1";
import PartTwo from "./part.2";
import PartThree from "./part.3.";

import {setToolState, setOpportunityCardState, activeOpportunity, resetTool} from '../redux_actions';
import "./tool.css";
let Tool = class Tool extends Component {
  render() {
    let {opportunityCardVisible, notesToolActive} = this.props.app.status;
    return <div className="tool-pg-cntr page">
      <div className="tool-cntr">
        {!opportunityCardVisible && !notesToolActive
          ? < PartOne />: null}
        {!notesToolActive
          ? < PartTwo />: null}
        {notesToolActive
          ? < PartThree />: null}
      </div>
    </div>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    setToolState,
    setOpportunityCardState,
    activeOpportunity,
    resetTool
  }, dispatch);
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Tool));