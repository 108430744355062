import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import "./report.css";
import {setToolState, addCustomFruits, makeContribution, resetTool} from '../redux_actions';
import FruitsCard from "./fruitsCard";
import AddFruit from "./addFruits";
import {Link} from "react-router-dom";

let Report = class channelDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myFruitsCardVisible: false,
      fruitCount: 0,
      fruitBasketKey: null,
      addFruitsVisible: false,
      domLoaded: false,
      cntrWidth: 0,
      cntrHeight: 0
    }
    this.setUpAddFruitsCard = (state) => {
      this.setState({addFruitsVisible: state})
    }
    this.setUpMyFruitsCard = (state) => {
      this.setState({myFruitsCardVisible: state})
    }
    this.setFruitCardContent = (fruitBasketKey, count) => {
      this.setState({fruitBasketKey, fruitCount: count})
    }
  }
  render() {
    let logId = this.props.app.activeLog.logId;
    let uid = this.props.auth.user.user.uid;
    let activeLog = this.props.app.activeLog

    let activeContributions = this.props.app.activeLog.log.contributions;
    let customFruits = this.props.app.activeLog.customFruits;
    let fruitBaskets = this.props.app.settings["-fruitBasket"];
    let fruits = this.props.app.settings["-fruits"];
    let probableResponses = this.props.app.settings["-probableResponses"];
    let fruitCount = 0;
    let userfruitBasket = {};
    Object
      .keys(activeContributions)
      .forEach(key => {
        let event = activeContributions[key];
        let _fruits = probableResponses[event.response].fruits||{};
        Object
          .keys(_fruits)
          .forEach(el => {
            Object
              .keys(fruits[el].basket)
              .forEach(basketKey => {
                if (!userfruitBasket[basketKey]) {
                  userfruitBasket[basketKey] = {
                    count: 0,
                    fruits: {}
                  };
                }
                userfruitBasket[basketKey].count = userfruitBasket[basketKey].count + 1;
                userfruitBasket[basketKey].fruits[el] = true;
                fruitCount++;
              });
          });
      });
    let sectionTitle = this.props.app.settings.texts["trigger-report"].replace("${fruitCount}", fruitCount)
    return <div className="app-report page">
      <div className="report-page">
        <div className="report-welcome-text">
          <div className="section-title">{sectionTitle}</div>
          <div className="section-subtitle">{this.props.app.settings.texts["trigger-report-subtitle"]}</div>
        </div>
        <div id="app-report-cntr" className="app-report-cntr">
          {this.state.domLoaded
            ? drawFruits(fruitBaskets, this.setUpMyFruitsCard, this.setFruitCardContent, userfruitBasket, this.state.cntrWidth, this.state.cntrHeight)
            : null}
        </div>
        <div
          className="more-fruits-cntr"
          onClick={() => {
          this.setUpAddFruitsCard(true)
        }}>
          <div>Got some other fruits that we missed?</div>
          <div style={{color: "blue"}}>Let us Know</div>
        </div>
      </div>
      <Link
        className="btn bottom report-done"
        to="/application"
        onClick={() => {
        this
          .props
          .resetTool()
      }}>Cool</Link>
      <FruitsCard
        state={this.state}
        handler={this.setUpMyFruitsCard}
        userfruitBasket={userfruitBasket}/>
      <AddFruit
        data={{
        uid,
        logId,
        activeLog
      }}
        state={this.state.addFruitsVisible}
        handler={this.setUpAddFruitsCard}
        customFruits={customFruits || ""}
        updater={this.props.addCustomFruits}/>
    </div>
  }

  componentDidMount() {
    let cntrWidth = document
      .getElementById("app-report-cntr")
      .offsetWidth;
    let cntrHeight = document
      .getElementById("app-report-cntr")
      .offsetHeight;

    this.setState({domLoaded: true, cntrWidth, cntrHeight});
  }
}

function drawFruits(fruitBaskets, setUpMyFruitsCard, setFruitCardContent, userfruitBasket, cntrWidth, cntrHeight) {
  return <svg
    xmlns="http://www.w3.org/svg/2000"
    viewBox={`0 0 ${cntrWidth} ${cntrHeight}`}
    width={cntrWidth}
    height={cntrHeight}>
    <filter id="blur">
      <feGaussianBlur in="SourceGraphic" stdDeviation="5"/>
    </filter>
    {Object
      .keys(fruitBaskets)
      .map(fruitBasketKey => {
        let fruitBasket = fruitBaskets[fruitBasketKey];
        let count = userfruitBasket[fruitBasketKey]&&userfruitBasket[fruitBasketKey].count?userfruitBasket[fruitBasketKey].count:0;
        let cx = fruitBasket.x * cntrWidth / 100,
          cy = fruitBasket.y * cntrHeight / 100;
          let R = 14, r = 9;
          let _Pi = 6.282;
        let titleOffset = fruitBasket.title.length * 3.25;
        return <g
          key={fruitBasketKey}
          onClick={(ev) => {
          setFruitCardContent(fruitBasketKey, userfruitBasket[fruitBasketKey]
            ? userfruitBasket[fruitBasketKey].count
            : 0);
          setUpMyFruitsCard(true)
        }}>{fruitBasket.type === "joy"
            ? <polygon
                className="joy-star"
                points={`${cx-R*Math.sin(0)} ${cy-R*Math.cos(0)},
                ${cx-r*Math.sin(_Pi*0.1)} ${cy-r*Math.cos(_Pi*0.1)}, 
                ${cx-R*Math.sin(_Pi*0.2)} ${cy-R*Math.cos(_Pi*0.2)}, 
                ${cx-r*Math.sin(_Pi*0.3)} ${cy-r*Math.cos(_Pi*0.3)},
                ${cx-R*Math.sin(_Pi*0.4)} ${cy-R*Math.cos(_Pi*0.4)},
                ${cx-r*Math.sin(_Pi*0.5)} ${cy-r*Math.cos(_Pi*0.5)},
                ${cx-R*Math.sin(_Pi*0.6)} ${cy-R*Math.cos(_Pi*0.6)},
                ${cx-r*Math.sin(_Pi*0.7)} ${cy-r*Math.cos(_Pi*0.7)},
                ${cx-R*Math.sin(_Pi*0.8)} ${cy-R*Math.cos(_Pi*0.8)},
                ${cx-r*Math.sin(_Pi*0.9)} ${cy-r*Math.cos(_Pi*0.9 )}`}
              />
            : <circle cx={cx} cy={cy} r="12" className={"fruit-svg " + fruitBasket.type}/>} 
            {<text x={cx - 4} y={cy + 5} className={"fruit-count " + fruitBasket.type}>{count}</text>}
          <text x={cx - titleOffset} y={cy + 28} className="fruit-name">{fruitBasket.title}</text>
        </g>
      })}
  </svg>;
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    setToolState,
    addCustomFruits,
    makeContribution,
    resetTool
  }, dispatch);
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Report));