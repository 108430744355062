import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

let Impact = (visibility, text, setImpactDialog, impactActionButton) => {
  function handleClose() {
    setImpactDialog(false)
  }
  return <Dialog
    open={false}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description">
    <div id="alert-dialog-slide-title">
      <img src="../images/thumbs.png" alt="thumbs-up" />
      <div className="section-title">Great Job!</div>
    </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          { impactActionButton }
          </Button>
        </DialogActions>
      </Dialog>
}

export default Impact