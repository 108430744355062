import React, {Component} from "react";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { withRouter } from "react-router-dom";

import {setToolState, rejectFruit} from '../redux_actions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

let FruitsCard = class FruitsCard extends Component {
  render() {
    let fruits = this.props.app.settings["-fruits"];
    let fruitBasket = this.props.app.settings["-fruitBasket"];
    let myFruitsCardVisible = this.props.state.myFruitsCardVisible;
    let fruitCount = this.props.state.fruitCount;
    let handler = this.props.handler;
    let userfruitBasket = this.props.userfruitBasket;
    let basketKey = this.props.state.fruitBasketKey;

    let PopulateFruits = () => {
      if (userfruitBasket[basketKey] && userfruitBasket[basketKey].fruits) {
        return Object
          .keys(userfruitBasket[basketKey].fruits)
          .map(fruitKey => {
            return <FormControlLabel
              key={fruitKey}
              control={< Checkbox checked = {
              !this.props.app.activeLog.fruitsRejected[fruitKey]
            }
            onChange = {
              () => {
                this.props.rejectFruit({fid:fruitKey, status: !this.props.app.activeLog.fruitsRejected[fruitKey]})
              }
            }
            value = {fruitKey} />}
              label={fruits[fruitKey].title}/>
          });
      } else {
        return null;
      }
    };
    return <Dialog
      open={myFruitsCardVisible}
      onClose={() => handler(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent>
        {/* <div>{fruitCount
            ? "You have got " + fruitCount + " fruits today of the following types"
            : "You have got no fruits in this catergory"}</div> */}
        {/* <PopulateFruits/> */}
        <div>{(fruitBasket[basketKey]&&fruitBasket[basketKey].description)?fruitBasket[basketKey].description:""}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handler(false)} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    setToolState, rejectFruit
  }, dispatch);
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(FruitsCard));