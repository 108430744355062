import React, {Component} from "react";
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';

import {makeContribution, setNotesTool, takeNotes, setImpactDialog} from '../redux_actions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let PartThree = class extends Component {
  render() {
    let that = this;
    let appState = this.props.app.status;
    let activeLog = this.props.app.activeLog;
    let activeCluster = appState.activeCluster;
    let setNotesTool = this.props.setNotesTool;
    let probableResponses = this.props.app.settings["-probableResponses"];
    let activeContribution = appState.activeContribution;
    let contribution = probableResponses[activeContribution];
    // let opportunities = this.props.app.settings["-opportunities"];
    let activeOpportunity = appState.activeOpportunity;
    // let opportunity = opportunities[activeOpportunity];
    let activeEventId = appState.activeEventId;
    let uid = this.props.auth.user.user.uid;
    
    return <Slide
      className="opportunity-cntr tool-pg"
      direction="left"
      mountOnEnter
      unmountOnExit
      in={appState.notesToolActive}>
      <div className="tool-cntr">
        <IconButton
          color="inherit"
          onClick={() => {
          setNotesTool(false);
          that.props.setImpactDialog(true);
        }}
          aria-label="Close">
          <CloseIcon/>
        </IconButton>
        <div className="contribution-header">
          <div>Moment of Contribution:</div>
          {/* <div>{opportunity.text || ""}</div> */}
          <div>{contribution.text || ""}</div>
        </div>
        <form noValidate autoComplete="off">
          <div className="section-sub-title trigger-part-three">{this.props.app.settings.texts["trigger-part-three"]}</div>
          <TextField
            id="notes"
            label="notes"
            className="notes"
            margin="normal"
            placeholder="Notes"
            multiline
            fullWidth
            value={activeLog.log.contributions[activeEventId].note}
            onChange={(ev) => {
            that
              .props
              .takeNotes({eventId: activeEventId, note: ev.target.value, cid: activeContribution});
          }}
            rowsMax={10}/>
        </form>
        <div
          className="active btn add-notes"
          onClick={() => {
          that
            .props
            .makeContribution({
              logId: activeLog.logId,
              log: activeLog.log,
              uid,
              oid: activeOpportunity,
              cid: activeContribution,
              eventId: activeEventId,
              activeCluster
            });
            that.props.setNotesTool(false);
            that.props.setImpactDialog(true);
        }}>Submit</div>
      </div>
    </Slide>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({
    makeContribution,
    setNotesTool,
    takeNotes,
    setImpactDialog
  }, dispatch);
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(PartThree));