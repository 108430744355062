const INITIAL_STATE = {
  settings: {
    texts: {
      "impact-action-continue" : "Continue",
      "trigger-part-one" : "Where did you contribute today?",
      "trigger-part-three" : "How did you contribute?",
      "trigger-part-two" : "What was the moment of contribution?",
      "trigger-report" : "Awesome. Do you want to add more?",
      "trigger-tool" : "What moments of contributions did you create today?"
    }        
  },
  myAccount: {
    firstName: "Guest"
  },
  status: {
    footerVisible: true,
    toolIsActive: false,
    opportunityCardVisible: false,
    notesToolActive: false,
    impactDialogActive: false,
    activeCluster: null,
    activeOpportunity: null,
    activeContribution: null,
    activeEventId: null
  },
  activeLog: {
    logId: null,
    createdOn: null,
    log: {},
    fruitBasket: {},
    fruitsRejected: {},
    customFruits:""
  }
};

export default(state = INITIAL_STATE, action) => {
  // console.log(action);
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;

    case "SET_FOOTER_VISIBILITY":
      return {
        ...state,
        status: {
          ...state.status,
          footerVisible: action.payload
        }
      };

    case "SET_TOOL_VISIBILITY":
      return {
        ...state,
        status: {
          ...state.status,
          toolIsActive: action.payload
        }
      };
    case "SET_CLUSTER":
      return {
        ...state,
        status: {
          ...state.status,
          activeCluster: action.payload
        }
      }

    case "SET_EVENT_ID":
      return {
        ...state,
        status: {
          ...state.status,
          activeEventId: action.payload
        }
      }

    case "SET_NOTE":
      return {
        ...state,
        activeLog: {
          ...state.activeLog,
          log: {
            ...state.activeLog.log,
            contributions: {
              ...state.activeLog.log.contributions,
              [action.payload.eventId]: {
                ...state.activeLog.log.contributions[action.payload.eventId],
                note: action.payload.note
              }
            }
          }
        }
      }

    case "SET_OPPORTUNITY_CARD_VISIBILITY":
      return {
        ...state,
        status: {
          ...state.status,
          opportunityCardVisible: action.payload
        }
      };

    case "SET_NOTE_TOOL":
      return {
        ...state,
        status: {
          ...state.status,
          notesToolActive: action.payload
        }
      };

    case "SET_IMPACT_DIALOG":
      return {
        ...state,
        status: {
          ...state.status,
          impactDialogActive: action.payload
        }
      };

    case "SELECT_OPPORTUNITY":
      return {
        ...state,
        status: {
          ...state.status,
          activeOpportunity: action.payload
        }
      };

    case "SELECT_CONTRIBUTION":
      return {
        ...state,
        status: {
          ...state.status,
          activeContribution: action.payload
        }
      };

    case "SET_ACTIVE_LOG":
      return {
        ...state,
        activeLog: {
          ...state.activeLog,
          logId: action.payload.logId,
          log: action.payload.log,
        }
      }

    case "RESET_TOOL":
    console.log(INITIAL_STATE.activeLog);
      return {
        ...state,
        activeLog: {logId: null,
          log: {},
          fruitBasket: {},
          fruitsRejected: {},
          customFruits:""},
        status: INITIAL_STATE.status
      }
    
    case "ADD_FRUITS":
      return {
        ...state,
        activeLog:{
          ...state.activeLog,
          fruitBasket:{
            ...state.activeLog.fruitBasket,
            [Object.keys(action.payload)[0]]: parseInt(state.activeLog.fruitBasket[Object.keys(action.payload)[0]]||0)+1
          }
        }
      }

    case "REJECT_FRUIT":
        return {
          ...state,
          activeLog:{
            ...state.activeLog,
            fruitsRejected:{
              ...state.activeLog.fruitsRejected,
              [action.payload.fid]: action.payload.status
            }
          }
        }

    case "ADD_CUSTOM_FRUITS":
      return {
        ...state,
        activeLog:{
          ...state.activeLog,
          customFruits: action.payload
        }
      }

    case "SYNC_LOCAL_FROM_GLOBAL":
      return {
        ...state,
        myAccount: action.payload && action.payload.userAccount
          ? action.payload.userAccount
          : {}
      };

    case "SYNC_APP_SETTINGS_FROM_GLOBAL":
      return {
        ...state,
        settings: action.payload
      };

    default:
      return state;
  }
};