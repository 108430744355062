import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {updateContribution} from '../redux_actions';

let AddFruit = class AddFruit extends Component{
  handleClose = () => {
    let {uid, logId} = this.props.data;
    let customFruits = document.getElementById("fruits-added").value;
    this.props.updater(customFruits);
    this.props.handler(false);
    updateContribution({uid, logId, customFruits})
  };
  render(){
    return <Dialog
    maxWidth="md"
    fullWidth
    open={this.props.state}
    onClose={this.handleClose}
    aria-labelledby="form-dialog-title"
  >
    <DialogContent>
      <TextField
        defaultValue={this.props.customFruits}
        rows= "5"
        multiline       
        margin="dense"
        id="fruits-added"
        label="What fruits did you get?"
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={this.handleClose} color="primary">
        Add these fruits
      </Button>
    </DialogActions>
  </Dialog>
  }
}

export default AddFruit;