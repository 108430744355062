import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import "./public.css"
let loginLink = props => <Link to="/auth/login" {...props}/>
let registerLink = props => <Link to="/auth/register" {...props}/>

const styles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: 'none'
  }
});

let Public = class Public extends Component {
  render() {
    const {classes} = this.props;
    return <div className="public-dashboard">
      <div className="public-dashboard-cntr">
        <div className="section-title">Tree of Fulfillment</div>
        <div className="section-subtitle">for Teacher</div>
        <div style={{paddingTop: "2rem"}}>
          <Button variant="outlined" component={loginLink} className={classes.button}>Sign In</Button>
          <Button variant="outlined" component={registerLink} className={classes.button}>Register</Button>
        </div>
      </div>
      <div className="brand-footer">
        <div><img alt="illumine-logo" src="https://s3.ap-south-1.amazonaws.com/illumine-logos/logo_illumine.png" height="64px" width="auto"/></div>
        <div>ILLUMINE</div>
      </div>
    </div>
  }
}

export default withStyles(styles)(Public)