import React, {Component} from "react";
import {connect} from "react-redux";
import { bindActionCreators } from "redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import "./appDashboard.css";
import {setToolState} from '../redux_actions';

let Dashboard = class channelDashboard extends Component {
  render() {
    return <div className="app-dashboard page">
      <div className="app-dashboard-cntr">
        {/* <div className="salutation">Welcome {this.props.app.myAccount.firstName}</div> */}
        <div className="call-to-action-cntr">
          {/* <div>Grow your tree</div> */}
          <div className="salutation">Welcome {this.props.app.myAccount.firstName},</div>
          <div className="section-title dashboard-text">{this.props.app.settings.texts["trigger-tool"] || ""}</div>
          <Link className="btn active tool-start" to="/application/tool">Add</Link>
        </div>
      </div>
      <div className="brand-footer">
        <div><img alt="illumine-logo" src="https://s3.ap-south-1.amazonaws.com/illumine-logos/logo_illumine.png" height="64px" width="auto"/></div>
        <div>ILLUMINE</div>
      </div>
    </div>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { setToolState },
    dispatch
  );
}
const mapStateToProps = state => ({app: state.app, auth: state.auth});
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Dashboard));